import {MenuItem} from '../core/menu/menu-item';
import {RolePermission} from 'smf-common';

const Suppliers: MenuItem = {
    text: 'Leads',
    icon: 'fa fa-id-card',
    link: '/supplier/leads',
    permission: RolePermission.SUPPLIER
};

const headingMain: MenuItem = {
  text: 'Main Navigation',
  heading: true
};

export const menu: Array<MenuItem> = [
  // headingMain,
  Suppliers
];

import {LayoutComponent} from '../layout/layout.component';

export const routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', redirectTo: 'supplier', pathMatch: 'full'},
      {path: 'supplier', loadChildren: './supplier/supplier.module#SupplierModule'},
    ]
  },
  // Not found
  {path: '**', redirectTo: ''}
];

export const environment = {
  production: window.env.type === 'production',
  projectName: window.env.projectName,
  deployment: {
    region: {
      name: window.env.regionName,
      lang: window.env.regionLang,
      currency: window.env.regionCurrency
    }
  },
  apiHost: window.env.apiHost,
  surveysUrl: window.env.surveysUrl,
  translationsUrl: `https://tolgee.prodsh-fnd.net/v2/projects/13/translations/{lng}`,
  authHosts: [{host: window.env.apiHost, paths: ['/api/']}],
  keycloakConfig: '',
  keycloak: {
    'realm': window.env.kcRealm,
    'auth-server-url': window.env.kcAuthUrl,
    'ssl-required': 'external',
    'resource': window.env.kcResource,
    'public-client': true,
    'confidential-port': 0
  },
  requireResourceAccess: true
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import {Component, HostBinding, OnInit, ViewChild, ViewChildDecorator} from '@angular/core';
declare var $: any;

import { SettingsService } from './core/settings/settings.service';
import {TemplateBinding} from '@angular/compiler';
import {Title} from "@angular/platform-browser";
import {environment} from "../environments/environment";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); }
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); }
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); }
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); }
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); }
    @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); }
    @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); }
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); }
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); }
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); }
    @HostBinding('class.layout-rtl') get isRtl() { return this.isRTLEnabled(); }
    @HostBinding('class.layout-ltr') get isLtr() { return !this.isRTLEnabled(); }

    public constructor(public settings: SettingsService, private titleService: Title) {
      this.settings.preloadBootstrap();
    }

    public ngOnInit() {
        this.setTitle(environment.projectName);
        $(document).on('click', '[href="#"]', e => e.preventDefault());
    }

    public setTitle(newTitle: string) {
      this.titleService.setTitle(newTitle);
    }

    private isRTLEnabled() {
      /// Fix for sweetalert2 and similar libs
      const isRTL = this.settings.getLayoutSetting('isRTL');
      window.document.body.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
      return isRTL;
    }

}

/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.0
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import './vendor.ts';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

declare global {
  interface Window {
    env: {
      type: 'production' | 'development',
      projectName: string,
      apiHost: string,
      surveysUrl: string,

      kcRealm: string,
      kcAuthUrl: string,
      kcResource: string,

      regionName: string,
      regionLang: string,
      regionCurrency: string
    }
  }
}
window.env = window.env || {} as Window['env']

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  (<any>window).appBootstrap && (<any>window).appBootstrap();
}).catch(err => {
  document.write(err);
});
